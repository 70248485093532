<template>
  <div class="uk-width-1-1">
    <div class="uk-background-default uk-padding-small">
      <form class="uk-form-stacked">
        <div class="uk-margin">
          <label class="uk-form-label">Inhalt</label>
          <input type="text" class="uk-input" v-model="form.content" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Link</label>
          <input type="text" class="uk-input" placeholder="http://" v-model="form.link" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    //
  },
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    this.form = this.data.props;
  },
  methods: {
    //
  }
};
</script>
